@import "/node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
:root {
    --zint-purple: #4A2673;
    --zint-dark-blue: #000A36;
    --zint-bear-blue: #5A79B9;
    --zint-steel-blue: #5A91A8;
    --zint-steel-blue07: #5a91a8b3;
    --zint-vivid-blue: #00A1E0;
    --zint-deep-blue: #4B4B6D;
    --zint-orange: #FA9F42;
    --zint-baby-blue: #B0BBDD;
    --zint-baby-grey: #A9A9B9;
    --zint-light-grey: #F0F0F0;
    --zint-light-green: #4BC767;
    --linkedin-blue: #0e76a8;

    --zint-purple-50: #F0EBF6;
    --zint-purple-100: #D2C2E4;
    --zint-purple-200: #BCA4D7;
    --zint-purple-300: #9D7AC4;
    --zint-purple-400: #8A61B9;
    --zint-purple-500: #6D39A7;
    --zint-purple-600: #633498;
    --zint-purple-700: #4D2877;
    --zint-purple-800: #3C1F5C;
    --zint-purple-900: #2E1846;

    --zint-blue-50: #EDF0F5;
    --zint-blue-100: #C6CFE1;
    --zint-blue-200: #ABB8D2;
    --zint-blue-300: #8498BD;
    --zint-blue-400: #6D84B1;
    --zint-blue-500: #48659D;
    --zint-blue-600: #425C8F;
    --zint-blue-700: #33486F;
    --zint-blue-800: #283856;
    --zint-blue-900: #1E2A42;

    --zint-orange-50: #FFF5EC;
    --zint-orange-100: #FDE1C4;
    --zint-orange-200: #FDD3A8;
    --zint-orange-300: #FCBF80;
    --zint-orange-400: #FBB268;
    --zint-orange-500: #FA9F42;
    --zint-orange-600: #E4913C;
    --zint-orange-700: #B2712F;
    --zint-orange-800: #8A5724;
    --zint-orange-900: #69431C;

    --zint-grey-50: #FBFBFB;
    --zint-grey-100: #FAF9FB;
    --zint-grey-150: #F4F5F7;
    --zint-grey-200: #F5F4F8;
    --zint-grey-300: #E9EBF8;
    --zint-grey-400: #C9C4D5;
    --zint-grey-500: #9991AB;
    --zint-grey-550: #585757;
    --zint-grey-600: #6D6381;
    --zint-grey-700: #322C40;
    --zint-grey-800: #282136;
    --zint-grey-900: #1A1624;

    --zint-red-100: #FFB6C1;
    --zint-red-200: #FF8C99;
    --zint-red-300: #FF6171;
    --zint-red-400: #FF3748;
    --zint-red-500: #FA4242;
    --zint-red-600: #F73939;
    --zint-red-700: #E32D2D;
    --zint-red-800: #C12121;
    --zint-red-900: #B00010;

    --zint-yellow-100: #FFFBCC;
    --zint-yellow-200: #FFF799;
    --zint-yellow-300: #FFF466;
    --zint-yellow-400: #FFEF33;
    --zint-yellow-500: #FFE700;
    --zint-yellow-600: #FFD800;
    --zint-yellow-700: #FFC900;
    --zint-yellow-800: #FFBA00;
    --zint-yellow-900: #FFAB00;  

    --zint-green-50: #E5F8EB;    
    --zint-green-100: #CCF1D6;    
    --zint-green-200: #9CD7AC;    
    --zint-green-300: #84CA97;  
    --zint-green-400: #6CBD82;    
    --zint-green-500: #4BC767;   
    --zint-green-600: #3C9E56;  
    --zint-green-700: #307544;    
    --zint-green-800: #235C33;   
    --zint-green-900: #194320;
}

html {
    scroll-behavior: smooth;
}


/* style override for material accordion and panels */
.padding-reset .mat-expansion-panel-body {
    padding: 0 !important;
}

/* mat-accordion override ends */

/* mat-menu overrides */
.mat-menu-panel {
    max-width: unset !important;
}
/* mat-menu overrides ends*/

/* stop ripple effect on material components */
.mat-ripple-element {
    display: none !important;
}
/* ripple override ends */

.card-header {
    background-color: var(--zint-grey-150) !important;
    border-bottom: none !important;
}

.card {
    border-color: #e5e7e9 !important;    
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--zint-grey-150) !important;
}


.overflowing-table {
    max-height: 300px;
    overflow-y: scroll !important;
}

.select-table {
    box-shadow: 0 0 2px 0 var(--zint-grey-400);
    padding: 0.25rem 1rem;
}

.table-layout-fixed {
    table-layout: fixed !important;
}

.table-within-table tr td:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-within-table tr td:last-child {
    width: 35%;
    text-align: center;
}

.table-within-table {
    table-layout: fixed;
}

/* general utility classes */
.bg-grey-50 {
    background-color: var(--zint-grey-50) !important;
}

.highlighted {
    color: var(--zint-orange-500) !important;
}

.extra-details {
    font-size: 0.75rem !important;
}

.fa-eco-icon {
    font-size: 1.13rem;
    text-shadow: 0.2px 0.2px;
}

.custom-fa-stack {
    width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--zint-grey-800) !important;


    i:nth-child(1) {
        font-size: 1.1rem;
    }

    i:nth-child(2) {
        position: absolute;
        font-size: 0.6rem;
    }
}

.text-vivid-blue {
    color:var(--zint-vivid-blue) !important;
}

.text-zint-dark-blue {
    color: var(--zint-dark-blue) !important;
}

.text-grey-300 {
    color: var(--zint-grey-300) !important;
}

.text-grey-400 {
    color: var(--zint-grey-400) !important;
}

.text-grey-600 {
    color: var(--zint-grey-600) !important;
}

.text-grey-800 {
    color: var(--zint-grey-800) !important;
}

.text-bold-600 {
    font-weight: 600 !important;
}

.text-bold-800 {
    font-weight: 800 !important;
}

.link-reset {
    color: unset !important;

    &:hover {
        text-decoration: none !important;
    }
}

.line-break-text {
    white-space: pre-line;
}

.zero-margin-bottom {
    margin-bottom: 0px !important;
}

.xsmall-margin-top {
    margin-top: 10px !important;
}

.xsmall-margin-bottom {
    margin-bottom: 10px !important;
}

.small-margin-top {
    margin-top: 25px !important;
}

.small-margin-bottom {
    margin-bottom: 25px !important;
}

.medium-margin-top {
    margin-top: 75px !important;
}

.large-margin-top {
    margin-top: 150px !important;
}

.large-margin-bottom {
    margin-bottom: 150px !important;
}

.medium-margin-bottom {
    margin-bottom: 75px !important;
}

.small-medium-margin-bottom {
    margin-bottom: 50px !important;
}

.xsmall-margin-right {
    margin-right: 10px;
}

.small-padding-bottom {
    padding-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.min-width-fit {
    min-width: fit-content;
}

.max-height-25vh {
    max-height: 25dvh !important;
    overflow-y: auto !important;
}

.max-height-40vh {
    max-height: 40dvh !important;
    overflow-y: auto !important;
}

.max-height-50vh {
    max-height: 50dvh !important;
    overflow-y: auto !important;
}

.max-height-75vh {
    max-height: 75dvh !important;
    overflow-y: auto !important;
}

.max-height-80vh {
    max-height: 80dvh !important;
    overflow-y: auto !important;
}

.max-height-90vh {
    max-height: 90dvh !important;
    overflow-y: auto !important;
}

.border-rounded-05 {
    border-radius: 0.5rem !important;
}

.border-rounded-top-05 {
    border-radius: 0.5rem 0.5rem 0 0 !important;
}

.border-rounded-bottom-05 {
    border-radius: 0 0 0.5rem 0.5rem !important;
}

.flex-grow-48 {
    flex: 1 0 48% !important;
}

.flex-gap-05 {
    gap: 0.5rem !important;
}
.flex-gap-1 {
    gap: 1rem !important;
}

.overflow-xy-visible {
    overflow-y: visible !important;
    overflow-x: visible !important;
}

/* ---- utility classes ends ---- */

/*---- button classes ----- */

.rounded-btn-base {
    border: 0.5px solid var(--zint-grey-400);
    border-radius: 8px;
    background-color: transparent;
    font-size: 1rem;    
    text-decoration: none;
    cursor: pointer;
}


.rounded-btn-white {
    background-color: white;
    color: var(--zint-dark-blue);

    &:hover {
        background-color: var(--zint-grey-50);
        color: var(--zint-orange);        
    }
}

.rounded-btn-vivid {
    background-color: var(--zint-vivid-blue);
    color: var(--zint-grey-50);

    &:hover {
        background-color: var(--zint-dark-blue);
        color: var(--zint-light-grey);        
    }
}

.rounded-btn-vivid-outline {
    background-color: none;
    border: 0.5px solid var(--zint-vivid-blue);
    color: var(--zint-blue-500);

    &:hover {
        background-color: var(--zint-vivid-blue);
        color: var(--zint-light-grey);               
    }
}

.rounded-btn-light-outline {
    background-color: none;
    border: 0.5px solid var(--zint-grey-300);
    color: var(--zint-grey-300);

    &:hover {
        background-color: var(--zint-grey-300);                    
        color: var(--zint-grey-700);  
    }
}

.rounded-btn-orange {
    background-color: var(--zint-orange);
    color: var(--zint-grey-100);
    
    &:hover {
        background-color: var(--zint-grey-50);
        color: var(--zint-orange-600);        
    }
}

.rounded-btn-light {
    background-color: var(--zint-grey-200);
    color: var(--zint-grey-800);
    
    &:hover {
        background-color: var(--zint-grey-50);        
    }
}


.rounded-btn-dark-hover {
    background-color: var(--zint-grey-200);
    color: var(--zint-grey-800);
    padding: 4px 12px;
    
    &:hover {
        background-color: var(--zint-dark-blue);        
        color: var(--zint-grey-200);
    }
}

.rounded-right-btn {
    border-radius: 0 8px 8px 0;
}

.rounded-left-btn {
    border-radius: 8px 0 0 8px;
}


.btn-link {
    text-align: center;
    text-decoration: none !important;
    color: var(--zint-vivid-blue) !important;

    &:hover {
        color: var(--zint-purple-700) !important;
    }
}

.btn-link-no-underline {

    &:hover {
        text-decoration: none !important;
    }
}


.btn-p-xs {
    padding: 2px 8px;
}

.btn-p-sm {
    padding: 4px 12px;
}

.btn-p-md {
    padding: 6px 24px;
}

.btn-p-lg {
    padding: 8px 40px;
}

.btn-p-xl {
    padding: 10px 64px;
}

.btn-p-xxl {
    padding: 12px 80px;
}

.basic-button-base {
    text-decoration: none;
    display: inline-block;
    text-align: center;
    border: solid 1px;
    border-radius: 1px !important;
    background-color: transparent;
    font-weight: 100 !important;
    padding: 5px 10px;
}


.homepage-button-base {
    text-decoration: none !important;
    display: inline-block;
    text-align: center;
    border: solid 1px;
    border-radius: 2px !important;
    background-color: transparent;
    font-weight: 100 !important;
    font-size: 26px;
    padding: 5px 10px 5px 10px;
    min-width: 180px;
    margin-top: 10px;
}

.homepage-button-base:hover {
    opacity: 0.9;
    cursor: pointer;
}

.button-light {
    color: white;
    border-color: white !important;
}

.button-light:hover {
    background-color: white;
    color: #000A36;
    cursor: pointer;
    text-decoration: none;
}

.button-dark {
    color: #000A36 !important;
    border-color: #000A36 !important;
}

.button-dark:hover {
    background-color: #000A36;
    color: white !important;
    cursor: pointer;
    text-decoration: none;
}

.button-dark-selected {
    background-color: #000A36;
    color: white !important;
    cursor: none;
}


.button-disabled {
    opacity: 0.8
}

.button-disabled:hover {
    color: #000A36 !important;
    background-color: inherit !important;
    cursor: not-allowed !important;
}

.button-orange {
    color: var(--zint-orange);
    border-color: var(--zint-orange);
}

.button-orange:hover {
    background-color: var(--zint-orange);
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.button-orange-selected {
    background-color: var(--zint-orange);
    color: white;
    cursor: none;
}

.button-purple {
    color: var(--zint-purple);
    border-color: var(--zint-purple);
}

.button-purple:hover {
    background-color: var(--zint-purple);
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.button-purple-selected {
    background-color: var(--zint-purple);
    color: white;
    cursor: none;
}

.button-bear {
    color: var(--zint-bear-blue);
    border-color: var(--zint-bear-blue);
}

.button-bear:hover {
    background-color: var(--zint-bear-blue);
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.button-bear-selected {
    background-color: var(--zint-bear-blue);
    color: white;
    cursor: none;
}

/* bootstrap btn override starts */
.no-focus-outline:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none ;
}
/* bootstrap btn override ends */

/* ------ button classes ends ------ */

/* ------ scrollbar force show  ------ */
.scrollbar-show {
    scrollbar-width: 0.45rem !important;
    scrollbar-color: var(--zint-grey-500) !important;    

    &::-webkit-scrollbar {
        width: 0.45rem !important;
        height: 0.45rem !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--zint-grey-500) !important;
        border-radius: 0.45rem !important;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--zint-grey-200) !important;
    }
}

/* ------- scrollbar force show ends ------ */



/* ------- other brand specific styles ------ */

.linkedIn-base-icon {
    font-size: 1rem !important;
    border-radius: 50% !important;
    padding: 3px 4px !important;
}

.linkedIn-icon-direct {
    color: var(--zint-grey-50) !important;
    background-color: var(--linkedin-blue) !important;
}

.linkedIn-icon-search {
    color: var(--zint-grey-100) !important;
    background-color: var(--zint-grey-400) !important;
}

/* ------- brand specific styles end ------ */




/* 
* bootstrap modal-xxl
* to be used with bootstrap modal-dialog
*/

@media (min-width: 600px) {
    .modal-xxl {
        max-width: min(800px, 90%) !important;
    }
}

@media (min-width: 800px) {
    .modal-xxl {
        max-width: min(1200px, 95%) !important;
    }
}

@media (min-width: 1200px) {
    .modal-xxl {
        max-width: min(1500px, 97%) !important;
    }
}

/* xxl modal ends */

body {
    font-weight: 400 !important;
    font-family: 'Assistant', sans-serif !important;
}

/* default checkbox style override */

input[type="checkbox"] {
    width: 16px;
    height: 16px;
    background-color: var(--zint-grey-50);
    border: 1px solid var(--zint-vivid-blue);
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    vertical-align: -1px;
}

input[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath stroke='%2300A1E0' stroke-width='2' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
}

input[type="checkbox"]:disabled {
    opacity: 0.6;
    border: 1px solid var(--zint-grey-400);
    cursor: not-allowed;
}

/* checkbox style override ends */


textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
button[type="submit"]:focus,
button:focus,
button:active,
.form-control:focus,
.custom-select:focus,
.form-group:focus {
    border-color: rgba(0, 10, 54, 0.25) !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 10, 54, 0.25) !important;
    outline: none !important;
}

.form-control {
    border-radius: 1px !important;
}



.custom-light-border {
    border: solid 1px #e5e7e9;
    border-radius: 1px
}

.small-padding {
    padding: 10px;
}

.cursor-pointer:hover {
    cursor: pointer !important;
}

.cursor-move:hover {
    cursor: grab;
}
.cursor-move:active {
    cursor: grabbing;
}

.not-allowed-cursor {
    cursor: not-allowed !important;
}


.custom-badge {
    background-color: #000a36 !important;
    font-weight: 100 !important;
    padding: 7px !important;
    border-radius: 8px !important;
}

.badge-close-button {
    margin-left: 5px;
    font-weight: bold;
    border: 0.5px solid var(--zint-grey-400);
    border-radius: 4px;
    padding-left: 2px;
    padding-right: 2px;
}

.badge-light-grey {
    display: inline-block;
    background-color: var(--zint-grey-200);
    border: 0.5px solid var(--zint-grey-400);
    border-radius: 4px;
    padding: 4px 10px;
    color: var(--zint-grey-600);
    white-space: nowrap;
}

.badge-vivid-blue {
    display: inline-block;
    background-color: #DCF5FF;
    color: var(--zint-vivid-blue);
    border-radius: 4px;
    padding: 0.25rem 0.4rem;
    font-size: 0.7rem;
    white-space: nowrap;
}


.overflow-scroll {
    white-space: nowrap !important;
    overflow-x: auto !important;
    display: block;
}



.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.dropdown-item:active {
    background-color: #000A36 !important;
    color: white !important;
}


.float-none {
    float: none !important;
}

.relative-position {
    position: relative;
}


.right-align-edit {
    position: absolute;
    right: 5px;
    top: 12px;
    margin-top: 3px;
}

.full-width {
    width: 100%
}

.cdk-overlay-container {
    z-index: 1200 !important;
}

button:disabled {
    cursor: not-allowed !important;   
}

.vertically-centre-text {
    margin-top: auto;
    margin-bottom: auto
}


.zint-href {
    color: rgb(33, 37, 41);
}

.zint-href:hover {
    color: black
}

.zint-muted-href {
    color: #3c5dc3;
}


.border-radius-10 {
    border-radius: 10px;
}

.img-max-height-150 {
    width: auto;
    max-height: 150px;
}

.xsmall-spacer {
    height: 10px;
}


.prevent-multi-line {
    white-space: nowrap;
    overflow-x: hidden;
}

.read-notifications {
    opacity: 0.4;
}


.ngx-graph-holder {
    width: 100% !important;
    min-height: 50dvh;
    max-height: 70dvh;
    overflow: hidden;
}





/*SVG Styles*/

.svg-arrow-fill{
     fill: var(--zint-vivid-blue);
}

.svg-arrow-stroke{
    stroke: var(--zint-vivid-blue);
}

/*SVG Styles*/





/*Information Text Boxes*/
.zint-base-card {
    border: 0.5px solid var(--zint-grey-300);
    background: linear-gradient(to right, var(--zint-blue-400), var(--zint-blue-700));
    box-shadow: 0 0 2px 0 var(--zint-grey-300);
    border-radius: 0.5rem;
    padding: 1rem;

    h1,h2,h3,h4,h5,h6,p,span {
        color: var(--zint-grey-300);
    }
}

.zint-light-card {
    background: unset;
    background-color: var(--zint-grey-50);
}

.zint-data-point-holder {
    padding: 2em 3em;
}

.zint-data-point-holder h4{
    margin-bottom: 2em;
    text-transform: uppercase;
    color: var(--zint-grey-300);
    font-size: 1em;
    font-weight: 600;
}

.zint-data-point-holder span{
    color: var(--zint-grey-50);
    font-size: 3em;
    font-weight: 100;
}

.zint-slider-name {   
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
}

/*Information Text Boxes*/



.fw-100{
    font-weight: 100;
}


/*New Zint Config Tables*/



.zint-config-table-container {
    overflow: hidden;
    box-shadow: 0 0 5px 5px var(--zint-grey-300);
    border-radius: 1em;
    display: inline-block;
    width: 100%;
}

.zint-config-table-container table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0px;
}

.zint-config-table-container thead {
    background-color: var(--zint-grey-300);
    border-radius: 5em;
}

.zint-config-table-container thead th {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    text-transform: uppercase !important;
    font-weight: 350;
    font-size: 1.3em;
}

.zint-config-table-container td {
    vertical-align: middle !important;
}

.zint-config-table-container .de-emphasised-text {
    color: var(--zint-grey-400);
    text-transform: uppercase !important;
    font-size: 0.7em;

}

.zint-rounded-table-container {
    border-radius: 0.5rem;
    box-shadow: 0 0 4px 0 var(--zint-grey-300);
    max-width: 100%;
    max-height: 40rem;
    border: 1rem solid white;
    overflow: auto;
}

.zint-rounded-table {
    margin-bottom: unset !important;
    color: var(--zint-grey-600);
    min-width: 50rem;
    overflow: auto;
    background-color: var(--zint-grey-50);
}

.zint-rounded-table th {
    color: var(--zint-dark-blue);
}

.zint-rounded-table .header-row {
    /* makes the header row sticky when scrolling vertically */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--zint-grey-50);
}

.zint-rounded-table-container .zint-rounded-table .header-row th {
    border-top: none;
    border-bottom: 0.5px solid var(--zint-grey-200);

    &:first-child {
        z-index: 13;
    }
}

.zint-rounded-table tr:first-child {
    border-top: 0.5px solid var(--zint-grey-300);
}

.zint-rounded-table tr:last-child {
    border-bottom: 0.5px solid var(--zint-grey-300);
}

.zint-rounded-table tbody tr:hover th,
.zint-rounded-table tbody tr:hover td {
    background-color: var(--zint-grey-200);
}

.zint-rounded-table .sticky-col {
    /* makes the column sticky when scrolling horizontally */
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    border-right: 0.5px solid var(--zint-grey-300);
    background-color: var(--zint-grey-50);
}

.zint-rounded-table .sticky-col::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.5px;
    box-shadow: 1px 0 0 var(--zint-grey-300);
}



.zint-config-table .left-hand-icon-column {
/*    TODO copy the styles in the refactoring UI book*/
}

/*New Zint Config Tables*/

